import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";

const agentLogin = sessionStorage.getItem("loginEmail");

function UpcomingCommission() {
  const [data, setData] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(null);
  const [masterData, setMasterData] = useState([]);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    try {
      const response = await axios.post(
        "https://royalluck.club:5000/user/upcommingCommissionForSuperMasterGreport",
        { loginId: agentLogin }
      );

      if (response.data.status === 200) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = (supermasterEmail) => {
    const filteredMasters = data.filter((item) => item.type === "Master");
    setMasterData(filteredMasters);
    setSelectedMaster(supermasterEmail);
  };

  const closeModal = () => {
    setSelectedMaster(null);
    setMasterData([]);
  };

  const columns = [
    { title: "Email", field: "name", cellStyle: { textAlign: "center" } },
    { title: "Type", field: "type", cellStyle: { textAlign: "center" } },
    {
      title: "Point Played",
      field: "points_played",
      cellStyle: { textAlign: "center" },
    },
    {
      title: "Commission",
      field: "commission",
      cellStyle: { textAlign: "center" },
    },
    // {
    //   title: "Action",
    //   render: (rowData) =>
    //     rowData.type === "Supermaster" ? (
    //       <button
    //         className="btn btn-danger"
    //         onClick={() => openModal(rowData.name)}
    //       >
    //         View Master Commission
    //       </button>
    //     ) : null,
    // },
  ];

  return (
    <>
      <div className="card card-outline" id="daily">
        <MaterialTable
          title="Upcoming Commission Details"
          data={data}
          columns={columns}
          options={{
            pageSize: 5,
            search: false,
            headerStyle: {
              textAlign: "center",
              background: "#f44336",
              color: "#FFF",
            },
            pageSizeOptions: [8, 16, 24],
          }}
        />
      </div>

      {selectedMaster && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ maxWidth: "600px", margin: "auto" }}
          >
            <div className="modal-content">
              {/* Modal Header */}
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#f7f7f7",
                  borderBottom: "1px solid #ddd",
                  padding: "15px",
                }}
              >
                <h5 className="modal-title" style={{ margin: 0 }}>
                  Master Commission Details
                </h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              {/* Modal Body */}
              <div className="modal-body" style={{ padding: "20px" }}>
                {masterData.length === 0 ? (
                  <p style={{ textAlign: "center" }}>No master data available</p>
                ) : (
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      textAlign: "center",
                    }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#ddd" }}>
                        <th style={{ padding: "12px", border: "1px solid #ddd" }}>
                          Email
                        </th>
                        <th style={{ padding: "12px", border: "1px solid #ddd" }}>
                          Commission
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {masterData.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                          }}
                        >
                          <td style={{ padding: "12px", border: "1px solid #ddd" }}>
                            {item.name}
                          </td>
                          <td style={{ padding: "12px", border: "1px solid #ddd" }}>
                            {item.commission}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              {/* Modal Footer */}
              <div
                className="modal-footer"
                style={{
                  borderTop: "1px solid #ddd",
                  padding: "15px",
                  textAlign: "right",
                }}
              >
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UpcomingCommission;
