import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Paper, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "../points/style/game.css";
import "../points/style/date.css";

function DailyStatus() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [singleDate, setSingleDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);

  const columns = [
    { title: "Player Name", field: "playerId" },
    { title: "Played Points", field: "playedPoint" },
    { title: "Win Points", field: "winPoint" },
    { title: "Loss Points", field: "losePoint" },
    {
      title: "Game ID",
      field: "game_id",
      render: (rowData) =>
        rowData.game_id === 8
          ? "Fun Target"
          : rowData.game_id === 3
          ? "Andar Bahar"
          : rowData.game_id === 7
          ? "Roulette"
          : rowData.game_id === 1
          ? "Seven Up"
          : rowData.game_id === 6
          ? "Triple Chance"
          : "Unknown Game",
    },
    {
      title: "Date & Time",
      field: "created_at",
      render: (rowData) =>
        moment(rowData.created_at).format("YYYY MMM DD, hh:mm A"),
    },
  ];

  const fetchPlayerData = async (loggedInUserEmail, selectedDate) => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/getPlayerDatas`, {
        manager: loggedInUserEmail,
        date: selectedDate,
      });

      if (response.data.status === 200) {
        setData(response.data.data);
        calculateTotals(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log("Error:", error);
      setData([]);
    }
  };

  const fetchMasterData = async (loggedInUserEmail, selectedDate) => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/getMasterDatas`, {
        manager: loggedInUserEmail,
        date: selectedDate,
      });
      if (response.data.status === 200) {
        setData(response.data.data);
        calculateTotals(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    const loggedInUserEmail = sessionStorage.getItem("loginEmail");
    if (loggedInUserEmail) {
      if (loggedInUserEmail === "master@domain.com") {
        fetchMasterData(loggedInUserEmail, null); // Master logic
      } else {
        fetchPlayerData(loggedInUserEmail, null); // Player logic
      }
    }
  }, []);

  const handleSubmit = async () => {
    const loggedInUserEmail = sessionStorage.getItem("loginEmail");

    if (!loggedInUserEmail) {
      return;
    }

    const selectedDate = singleDate
      ? moment(singleDate).format("YYYY-MM-DD")
      : null;

    const todayDate = moment().format("YYYY-MM-DD");
    if (selectedDate === todayDate) {
    }

    if (selectedDate) {
      if (loggedInUserEmail === "master@domain.com") {
        await fetchMasterData(loggedInUserEmail, selectedDate);
      } else {
        await fetchPlayerData(loggedInUserEmail, selectedDate);
      }
    } else {
      if (loggedInUserEmail === "master@domain.com") {
        await fetchMasterData(loggedInUserEmail, null);
      } else {
        await fetchPlayerData(loggedInUserEmail, null);
      }
    }
  };

  const calculateTotals = (data) => {
    const totalPlayed = data.reduce(
      (sum, row) => sum + (row.playedPoint || 0),
      0
    );
    const totalLoss = data.reduce((sum, row) => sum + (row.losePoint || 0), 0);
    setTotalPlayed(totalPlayed);
    setTotalLoss(totalLoss);
  };

  return (
    <Paper sx={{ padding: "20px", marginBottom: "20px" }}>
      <Typography variant="h6" gutterBottom>
        Daily Status
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            label="Select Date"
            type="date"
            fullWidth
            variant="outlined"
            value={singleDate ? moment(singleDate).format("YYYY-MM-DD") : ""}
            onChange={(e) =>
              setSingleDate(moment(e.target.value).format("YYYY-MM-DD"))
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                max: moment().format("YYYY-MM-DD"),
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{
              height: "100%",
              borderRadius: "50px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#d32f2f",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                transform: "scale(1.05)",
                transition: "all 0.3s ease",
              },
              padding: "12px 24px",
            }}
          >
            Show Details
          </Button>
        </Grid>
      </Grid>

      <div className="card card-outline" id="daily">
        <MaterialTable
          title="Daily Status"
          data={data && data.length > 0 ? data : []}
          columns={columns}
          options={{
            pageSize: 20,
            pageSizeOptions: [10, 20, 50],
            paging: true,
            search: false,
            toolbar: true,
            header: true,
            tableLayout: "fixed",
            maxBodyHeight: "500px",
            headerStyle: {
              textAlign: "center",
              whiteSpace: "nowrap",
              height: 20,
              background: "#f44336",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },
            rowStyle: (data, index) => ({
              background: index % 2 === 0 ? "#e1a49f" : "white",
            }),
            cellStyle: { border: "1px solid black", textAlign: "center" },
            paginationType: "normal",
          }}
          onChangePage={(page) => console.log("Page Changed:", page)}
          onChangeRowsPerPage={(pageSize) =>
            console.log("Rows Per Page Changed:", pageSize)
          }
          detailPanel={[
            {
              tooltip: "Totals",
              render: () => (
                <div style={{ padding: "10px", fontWeight: "bold" }}>
                  <span>Total Played Points: {totalPlayed}</span>
                  <br />
                  <span>Total Loss Points: {totalLoss}</span>
                </div>
              ),
            },
          ]}
        />
      </div>
    </Paper>
  );
}

export default DailyStatus;
