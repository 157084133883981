import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableBody,
} from "material-table";
import "../../style/Contact.css";
import "../points/style/game.css";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";

import Modal from "react-bootstrap/Modal";

import Button from "react-bootstrap/Button";
// import { apiBaseURL } from "../../config";
import moment from "moment";
import clubImage from '../../image/cards/club.png';
import spadeImage from '../../image/cards/spade.png';
import diamondImage from '../../image/cards/diamond.jpg';
import heartImage from '../../image/cards/heart.png';
let apiBaseURL = "https://royalluck.club:5000";

// import { authToken } from "../../authToken";

function FunAB() {
  const [data, setData] = useState([]);

  // get Agents
  const gameReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/AndarBaharGamePlayHistory`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {});
  };

  const mapCardNumberToNameAndSuit = (number) => {
    const cardNames = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
    const suits = ['diamond', 'heart' ,'club', 'spade'];
    
    const suitIndex = Math.floor(number / 13);
    const cardName = cardNames[number % 13];
    const suit = suits[suitIndex];
    
    return `${cardName}`;
  };
  //get Agents

  const columns = [
    { title: "Sr.No.", render: (rowData) => rowData.tableData.id + 1 },
    { 
      title: "Joker",
      field: "finalresult",
      render: rowData => {
        // Conditionally render an image based on the value of finalResult
        let imageSrc;
        if(rowData.finalresult < 13) {
          imageSrc = diamondImage;
        }else if(rowData.finalresult >= 13 && rowData.finalresult < 26){
          imageSrc = heartImage;
        }else if(rowData.finalresult >= 26 && rowData.finalresult < 39){
          imageSrc = clubImage;
        }else if(rowData.finalresult >= 39 && rowData.finalresult < 52){
          imageSrc = spadeImage;
        }
        const cardNumber = rowData.finalresult; // Assuming finalresult contains card number
        const cardNameAndSuit = mapCardNumberToNameAndSuit(cardNumber);
        // Render the image if imageSrc is available, otherwise render null
        return (
          <div>
            {imageSrc && <img src={imageSrc} alt={rowData.finalresult} style={{ width: '30px', height: '30px' }} />}
            &nbsp;{cardNameAndSuit}
          </div>
        )
      }
    },
    { title: "Winning Side", field: "Cardresult",
       render: rowData => {
        return (
             rowData.Cardresult === 1 ? "Bahar" : "Andar"
        )
       }
     },
     {title :"Round Count", field:"RoundCount"},
    { title: "Date & Time", render: rowData => moment(rowData.playedtime).format("DD-MM-YYYY h:mm:ss ") }, 

  ];

  useEffect(() => {
    gameReports();
  }, []);
  return (
    <>
      <div className="card card-outline ">
        <MaterialTable
          title="Fun AB"
          data={data}
          columns={columns}
          options={{
            pageSize: 20,
            search: false,

            toolbar: true,
            header: true,
            thirdSortClick: false,

            headerStyle: {
              background: "red",
              position: "sticky",
              top: "0",
            },
            tableLayout: "fixed",
            maxBodyHeight: "300px",

            headerStyle: {
              background: "orange",
              fontStyle: "italic",
              border: "1px solid black",
            },
            tollbarStyle: { width: "1000%" },
            headerStyle: {
              textAlign: "center",
              whiteSpace: "nowrap",
              height: 20,
              maxHeight: 20,
              padding: 0,
              background: "#f44336",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },

            rowStyle: {
              width: 20,
              maxWidth: 20,
              height: 20,
              maxHeight: 20,
              padding: 0,
            },
            toolbarStyle: {
              height: 10,
              maxHeight: 10,
            },

            rowStyle: { border: "1px solid black" },
            rowStyle: (data, index) =>
              index % 2 == 0 ? { background: "#e1a49f" } : null,
            cellStyle: { border: "1px solid black", alignItems: "center" },
            paging: true,
          }}
          components={{
            Toolbar: (props) => (
              <div
                style={{ background: "orange", borderSpacing: "5px" }}
              >
                <MTableToolbar {...props} />
              </div>
            ),
            // Pagination: props => (
            //     <div style={{ backgroundColor: '#b2994e' , }}>
            //         <MTablePagination {...props} />
            //     </div>
            // ),
            Row: (props) => (
              <div style={{}}>
                <MTableBodyRow {...props} />
              </div>
            ),

            Header: (props) => (
              <div>
                <MTableHeader {...props} />
              </div>
            ),
            Body: (props) => (
              <div style={{}}>
                <MTableBody {...props} />
              </div>
            ),
          }}
        />
      </div>
    </>
  );
}

export default FunAB;
