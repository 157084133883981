import React, { useState } from "react";
import DatePicker from "react-datepicker";
import {
  CAccordionItem,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
import axios from "axios";
import "./Home.css";
import {
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { RsvpTwoTone } from "@mui/icons-material";
function TransactionReport() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [id, setId] = useState("");
  const handleFromDateChange = (date) => {
    setStartDate(date);
  };
  const handleToDateChange = (date) => {
    setEndDate(date);
  };
  const agentLogin = sessionStorage.getItem("loginEmail");
  function showNotification(message) {
    const notification = document.getElementById("notification");
    notification.textContent = message;
    notification.style.display = "block";
    setTimeout(() => {
      hideNotification();
    }, 2000);
  }
  function hideNotification() {
    const notification = document.getElementById("notification");
    notification.style.display = "none";
  }
  const handleFetchData = async () => {
    console.log("handlefetchdata called");
    let emailId = sessionStorage.getItem("loginEmail");

    try {
      let payload = { emailId };

      if (id) payload.receiverId = id;

      let validFromDate = fromDate
        ? new Date(fromDate).toISOString().split("T")[0]
        : "";
      let validToDate = toDate
        ? new Date(toDate).toISOString().split("T")[0]
        : "";

      if (validFromDate) payload.fromDate = validFromDate;
      if (validToDate) payload.toDate = validToDate;

      const response1 = await axios.post(
        `${apiBaseURL}/accountGreport/transferred`,
        payload
      );

      if (
        Array.isArray(response1.data.data) &&
        response1.data.data.length === 0
      ) {
        setError1("No Data Available");
        setTableData1([]);
      } else {
        console.log("RESPONSE1 DATA TRANSFER POINT", response1.data.data);
        setTableData1(response1.data.data || []);
        setError1("");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setTableData1([]);
    }

    try {
      let payload = { emailId };

      if (id) payload.receiverId = id;

      let validFromDate = fromDate
        ? new Date(fromDate).toISOString().split("T")[0]
        : "";
      let validToDate = toDate
        ? new Date(toDate).toISOString().split("T")[0]
        : "";

      if (validFromDate) payload.fromDate = validFromDate;
      if (validToDate) payload.toDate = validToDate;

      console.log("Payload being sent:", payload);

      const response2 = await axios.post(
        `${apiBaseURL}/accountGreport/transferNotReceived`,
        payload
      );

      if (
        Array.isArray(response2.data.data) &&
        response2.data.data.length === 0
      ) {
        setError2("No Data Available");
        setTableData2([]);
      } else {
        console.log("RESPONSE2 DATA TRANSFER POINT", response2.data.data);
        setTableData2(response2.data.data || []);
        setError2("");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setTableData2([]);
    }

    try {
      let payload = { emailId };

      if (id) payload.receiverId = id;

      let validFromDate = fromDate
        ? new Date(fromDate).toISOString().split("T")[0]
        : "";
      let validToDate = toDate
        ? new Date(toDate).toISOString().split("T")[0]
        : "";

      if (validFromDate) payload.fromDate = validFromDate;
      if (validToDate) payload.toDate = validToDate;

      const response3 = await axios.post(
        `${apiBaseURL}/accountGreport/receive`,
        payload
      );

      if (
        Array.isArray(response3.data.data) &&
        response3.data.data.length === 0
      ) {
        setError3("No Data Available");
        setTableData3([]);
      } else {
        setTableData3(response3.data.data || []);
        setError3("");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setTableData3([]);
    }

    try {
      let payload = { emailId };

      if (id) payload.receiverId = id;

      let validFromDate = fromDate
        ? new Date(fromDate).toISOString().split("T")[0]
        : "";
      let validToDate = toDate
        ? new Date(toDate).toISOString().split("T")[0]
        : "";

      if (validFromDate) payload.fromDate = validFromDate;
      if (validToDate) payload.toDate = validToDate;

      console.log("Payload being sent:", payload);

      const response4 = await axios.post(
        `${apiBaseURL}/accountGreport/reject`,
        payload
      );

      if (
        Array.isArray(response4.data.data) &&
        response4.data.data.length === 0
      ) {
        setError4("No Data Available");
        setTableData4([]);
      } else {
        console.log("RESPONSE2 DATA TRANSFER POINT", response4.data.data);
        setTableData4(response4.data.data || []);
        setError4("");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setTableData4([]);
    }

    try {
      let payload = { emailId };

      if (id) payload.receiverId = id;

      let validFromDate = fromDate
        ? new Date(fromDate).toISOString().split("T")[0]
        : "";
      let validToDate = toDate
        ? new Date(toDate).toISOString().split("T")[0]
        : "";

      if (validFromDate) payload.fromDate = validFromDate;
      if (validToDate) payload.toDate = validToDate;

      console.log("Payload being sent:", payload);

      const response5 = await axios.post(
        `${apiBaseURL}/accountGreport/reject`,
        payload
      );

      if (
        Array.isArray(response5.data.data) &&
        response5.data.data.length === 0
      ) {
        setError5("No Data Available");
        setTableData5([]);
      } else {
        console.log("RESPONSE2 DATA TRANSFER POINT", response5.data.data);
        setTableData5(response5.data.data || []);
        setError5("");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setTableData5([]);
    }

    showNotification("Details fetched successfully!");
  };

  const totalAmount = tableData1.reduce((acc, row) => acc + row.point, 0);
  return (
    <>
      <div className="Homebg">
        <div className="div1">
          <h2>Point Transfer</h2>
          <div className="row">
            <div>
              <form action="" method="post">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <div className="row-left">
                          <label>From Date:</label>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            dateFormat="dd MMM yyyy"
                            calendarClassName="custom-datrpicker"
                            selectsStart
                            startDate={fromDate}
                            endDate={toDate}
                          />
                        </div>
                        <div className="row-mid">
                          <label>To Date:</label>
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            dateFormat="dd MMM yyyy"
                            calendarClassName="custom-datrpicker"
                            selectsEnd
                            startDate={fromDate}
                            endDate={toDate}
                            minDate={fromDate}
                          />
                        </div>
                        <div className="row-mid">
                          <label>MemberID</label>
                          <input
                            type="text"
                            onChange={(e) => setId(e.target.value)}
                            value={id}
                          />
                        </div>

                        <div class="row-right show-details-button">
                          <input
                            type="button"
                            onClick={handleFetchData}
                            name="btnShowDetails"
                            id="btnShowDetails"
                            value="Show Details"
                            onmouseover="showNotification('Details fetched successfully!')"
                            onmouseout="hideNotification()"
                          />
                        </div>
                        <div id="notification" class="notification"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="row" id="accor">
                          <CAccordion>
                            <CAccordionItem itemKey={1}>
                              <CAccordionHeader>
                                Point Transferred
                              </CAccordionHeader>
                              <CAccordionBody>
                                {error1 && (
                                  <div style={{ color: "black" }}>{error1}</div>
                                )}
                                {tableData1.length > 0 && (
                                  <TableContainer component={Paper}>
                                    <Table>
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Sr.No.
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Transferred To
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Transfer Time
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {tableData1.map((row, index) => (
                                          <TableRow
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                index === 0 || index === 2
                                                  ? "#F4CBC8"
                                                  : "white",
                                              padding: "0px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.receiverId === "RL0000" ? "Company" : row.receiverId}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.point}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {new Date(row.dateTime)
                                                .toLocaleString("en-IN", {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  second: "2-digit",
                                                  hour12: true,
                                                })
                                                .replace(/\//g, "-")
                                                .replace(",", "")}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                        <TableRow>
                                          <TableCell colSpan={2}></TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "right",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Total
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {totalAmount}
                                          </TableCell>
                                          <TableCell></TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </CAccordionBody>
                            </CAccordionItem>
                            <CAccordionItem itemKey={2}>
                              <CAccordionHeader>
                                Points Transferred but yet to be Received
                              </CAccordionHeader>
                              <CAccordionBody>
                                {error2 && (
                                  <div style={{ color: "black" }}>{error2}</div>
                                )}
                                {tableData2.length > 0 && (
                                  <TableContainer component={Paper}>
                                    <Table>
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            FromUser
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            ToUser
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Transfer Time
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {tableData2.map((row, index) => (
                                          <TableRow
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                index === 0 || index === 2
                                                  ? "#F4CBC8"
                                                  : "white",
                                              padding: "0px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.senderId === "admin@admin.com" ? "Admin": row.senderId === "RL0000" ? "Company": row.senderId}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.receiverId === "RL0000" ? "Company" : row.receiverId}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.point}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {(() => {
                                                const [date, time] =
                                                  row.dateTime.split(", ");
                                                const [day, month, year] =
                                                  date.split("/");
                                                const formattedDate = new Date(
                                                  `${year}-${month}-${day} ${time}`
                                                );
                                                return formattedDate
                                                  .toLocaleString("en-IN", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                    hour12: true,
                                                  })
                                                  .replace(/\//g, "-")
                                                  .replace(",", "");
                                              })()}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </CAccordionBody>
                            </CAccordionItem>
                            <CAccordionItem itemKey={3}>
                              <CAccordionHeader>
                                Points Received
                              </CAccordionHeader>
                              <CAccordionBody>
                                {error3 && (
                                  <div style={{ color: "black" }}>{error3}</div>
                                )}
                                {tableData3.length > 0 && (
                                  <TableContainer component={Paper}>
                                    <Table>
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Sr.No.
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Received From
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Received Date
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {tableData3.map((row, index) => (
                                          <TableRow
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                index === 0 || index === 2
                                                  ? "#F4CBC8"
                                                  : "white",
                                              padding: "0px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                padding: "0px",

                                                textAlign: "center",
                                              }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.senderId === "admin@admin.com" ? "Admin": row.senderId === "RL0000" ? "Company": row.senderId}
                                            </TableCell>

                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.point}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {(() => {
                                                if (!row.dateTime)
                                                  return "Invalid Date";

                                                const [date, time, meridian] =
                                                  row.dateTime.split(/[, ]+/);
                                                const [day, month, year] = date
                                                  .split("/")
                                                  .map(Number);
                                                const [
                                                  hours,
                                                  minutes,
                                                  seconds,
                                                ] = time.split(":").map(Number);

                                                let adjustedHours =
                                                  meridian.toLowerCase() ===
                                                    "pm" && hours !== 12
                                                    ? hours + 12
                                                    : hours;
                                                adjustedHours =
                                                  meridian.toLowerCase() ===
                                                    "am" && hours === 12
                                                    ? 0
                                                    : adjustedHours;

                                                const formattedDate = new Date(
                                                  year,
                                                  month - 1,
                                                  day,
                                                  adjustedHours,
                                                  minutes,
                                                  seconds
                                                );

                                                return formattedDate
                                                  .toLocaleString("en-IN", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                    hour12: true,
                                                  })
                                                  .replace(/\//g, "-")
                                                  .replace(",", "");
                                              })()}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </CAccordionBody>
                            </CAccordionItem>
                            <CAccordionItem itemKey={4}>
                              <CAccordionHeader>
                                Points Rejected
                              </CAccordionHeader>
                              <CAccordionBody>
                                {error4 && (
                                  <div style={{ color: "black" }}>{error4}</div>
                                )}
                                {tableData4.length > 0 && (
                                  <TableContainer component={Paper}>
                                    <Table>
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              color: "white",

                                              textAlign: "center",
                                            }}
                                          >
                                            Sr.No.
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Rejected To
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Rejected Date
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {tableData4.map((row, index) => (
                                          <TableRow
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                index === 0 || index === 2
                                                  ? "#F4CBC8"
                                                  : "white",
                                              padding: "0px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                padding: "0px",

                                                textAlign: "center",
                                              }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.senderId === "admin@admin.com" ? "Admin": row.senderId === "RL0000" ? "Company": row.senderId}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.point}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {new Date(row.dateTime)
                                                .toLocaleString("en-IN", {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  second: "2-digit",
                                                  hour12: true,
                                                })
                                                .replace(/\//g, "-")
                                                .replace(",", "")}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </CAccordionBody>
                            </CAccordionItem>
                            <CAccordionItem itemKey={5}>
                              <CAccordionHeader>
                                Points Cancelled
                              </CAccordionHeader>
                              <CAccordionBody>
                                {error5 && (
                                  <div style={{ color: "black" }}>{error5}</div>
                                )}
                                {tableData5.length > 0 && (
                                  <TableContainer component={Paper}>
                                    <Table>
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            textAlign: "center",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              color: "white",

                                              textAlign: "center",
                                            }}
                                          >
                                            Sr.No.
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Cancelled To
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Amount
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                            }}
                                          >
                                            Cancelled Date
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {tableData5.map((row, index) => (
                                          <TableRow
                                            key={index}
                                            style={{
                                              backgroundColor:
                                                index === 0 || index === 2
                                                  ? "#F4CBC8"
                                                  : "white",
                                              padding: "0px",
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                padding: "0px",

                                                textAlign: "center",
                                              }}
                                            >
                                              {index + 1}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.receiverId === "RL0000" ? "Company" : row.receiverId}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {row.point}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                padding: "0px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {new Date(row.dateTime)
                                                .toLocaleString("en-IN", {
                                                  year: "numeric",
                                                  month: "2-digit",
                                                  day: "2-digit",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                  second: "2-digit",
                                                  hour12: true,
                                                })
                                                .replace(/\//g, "-")
                                                .replace(",", "")}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </CAccordionBody>
                            </CAccordionItem>
                          </CAccordion>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TransactionReport;
