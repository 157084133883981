import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../style/Login.css";
import axios from "axios";
import { apiBaseURL } from "../config";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel
} from "@mui/material";

const Login = ({ setToken }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    captcha: "",
  });
  const [error, setErrorMsg] = useState(false);
  const [captchaNumber, setCaptchaNumber] = useState(generateRandomCaptcha());
  const [captchaText, setCaptchaText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setValues({
        email: savedEmail,
        password: savedPassword,
        captcha: "",
      });
      setRememberMe(true);
    }
  }, []);

  function generateRandomCaptcha() {
    const min = 10000;
    const max = 899999;
    let randomNumber = Math.floor(min + Math.random() * (max - min + 1));
    return randomNumber;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = values;
    const user = { email, password };

    if (captchaText !== captchaNumber.toString()) {
      setErrorMsg("captcha verification failed");
      return;
    }

    if (!email || !password || !captchaText) {
      setErrorMessage("Please provide both Email and Password and captcha");
      return;
    }

    try {
      const roleIdResponse = await axios.post(`${apiBaseURL}/auth/getRoleId`, {
        email,
      });
      const roleId = roleIdResponse.data.roleId;
      sessionStorage.setItem("roleId", roleId);
    } catch (error) {
      console.log("Error retrieving roleId:", error);
    }

    try {
      const response = await axios.post(`${apiBaseURL}/auth/login`, user);
      sessionStorage.setItem("loginEmail", response.data.data.user_id);

      if (response.data.status === 200) {
        setToken(response.data.token);
        setErrorMsg(response.data.message);
        if (rememberMe) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
      } else {
        setErrorMsg(response.data.message);
        sessionStorage.removeItem("loginEmail");
      }
    } catch (error) {
      console.log("Error occurred:", error);
      sessionStorage.removeItem("loginEmail");
    }
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  return (
    <Box
      sx={{
        backgroundImage: `url('/background1.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        height: "100vh",
        width: "100%",
        position: "relative",
        "@media (max-width: 1024px)": {
          backgroundSize: "contain",
        },
        "@media (max-width: 600px)": {
          backgroundSize: "cover",
          backgroundAttachment: "scroll",
          backgroundPosition: "center",
        },
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          padding: 2,
          borderRadius: 2,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="left"
          sx={{
            fontWeight: 700,
            color: "primary.main",
            fontSize: { xs: "1.5rem", sm: "2rem" },
            textAlign: "left",
          }}
        >
          Royal Luck
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email or Username"
                value={values.email}
                onChange={handleChange("email")}
                name="email"
                type="text"
                variant="outlined"
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                value={values.password}
                onChange={handleChange("password")}
                name="email"
                type="text"
                variant="outlined"
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Enter Image Text"
                value={captchaText}
                onChange={(e) => setCaptchaText(e.target.value)}
                type="text"
                variant="outlined"
                required
                size="medium"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                className="imgCaptcha"
                id="imgCaptcha"
                disabled
                value={captchaNumber}
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  fontSize: "20px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    color="primary"
                  />
                }
                label="Remember Me"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                sx={{ padding: "10px" }}
              >
                SIGN IN
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <div className="alert alert-warning" role="alert">
                  {error}
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
