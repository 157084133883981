import React, { useState,useEffect } from 'react'
import "../../../style/Login.css"
const Nav = () => {
  const agentLogin = sessionStorage.getItem("loginEmail");
        const log = async () =>{
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("loginEmail");
         
          window.location.href='/';
      }

const [active,setActive]=useState('Reports');
 const handleClinklink=(li)=>{
  setActive(active === li ? null :li);
 }
//  useEffect(() => {
  
//   const tokenData = JSON.parse(sessionStorage.getItem("token"));
//   if (!tokenData) {
//     sessionStorage.removeItem("token");
//     window.location.reload();
//   }

//   const userLogin = sessionStorage.getItem("loginEmail");
//   const masteridLogin = sessionStorage.getItem1("loginEmail1");
//   const supermasterLogin = sessionStorage.getItem2("loginEmail2");


//   if (userLogin==masteridLogin ==supermasterLogin) {
    
//     document.getElementById("Revenue").style.display = "none";
//   } else {

   
    
//     document.getElementById("Revenue").style.display = "visible";

//   }

// }, []);


  return (
    <>
    <div className='container'>
    <div class="header">
      
       {/* <div class="logo">
       
            <img src={require("../../../image/logo.png")} alt="Logo"/>
      
       </div> */}
      
 
       <div class="user-details" style={{	background:'#fff'}}>            	
        <span style={{	background:'#fff'}}>Welcome</span> <></>
        <strong id="logout" style={{	background:'#fff'}}><em style={{	background:'#fff'}}>{agentLogin}</em>&nbsp;</strong>
       </div>
       <div class="panel">
        <a href="/" title="Logout"><i class="fa-power-off">&nbsp; Logout</i></a>
       </div>
      
       
  <nav>
    <ul className="responsive_menu" id="menu4">
   <li><a href="#" onClick={()=>handleClinklink('Reports')} style={{backgroundColor:active ==='Reports' ? '#f44336':'',padding:"5px"}}>Reports</a>
   {active === 'Reports' &&(
   <ul><li>
     <a href="/">Point Transfer</a></li><li>
       <a href="/MultipointTransfer">Multiplayer Point Transfer</a></li></ul>
       )}
       </li>
   
       <li id="Revenue">
         <a href="#" onClick={()=>handleClinklink('Revenue')} style={{backgroundColor:active ==='Revenue' ? '#f44336':'',padding:"5px"}}>Revenue</a>
         {active === 'Revenue' &&(
         <ul>
            <li><a href="/DailyStatus"  style={{backgroundColor:active ==='DailyStatus' ? '#f44336':'',padding:"5px"}} >Daily Status</a></li>
            <li>
           <a href="/MonthlyStatus"  style={{backgroundColor:active ==='MonthlyStatus' ? '#f44336':'',padding:"5px"}}>Monthly Status</a></li>
           <li>
           <a href="/UpcomingCommission"  style={{backgroundColor:active ==='UpcomingCommission' ? '#f44336':'',padding:"5px"}}>Upcoming Commision Details</a></li>
           </ul>
           )}
       </li>
       <li>
         <a href="#" onClick={()=>handleClinklink('Draw Details')} style={{backgroundColor:active ==='Draw Details' ? '#f44336':'',padding:"5px"}}>Draw Details</a>
         {active === 'Draw Details' &&(
         <ul>
            <li><a href="/FunTarget">Fun Target</a></li>
            <li>
           <a href="/FunRoullet">Fun Roullet</a></li>
           <li><a href="/TripleFun">Triple Fun</a></li>
           <li><a href="/FunAB">Fun AB</a></li>
           <li><a href="/SevenUpAndDown">7Up and Down</a></li>
           
           </ul>
           )}
           </li>
           <li><a href="/" onClick={() => log()}  style={{backgroundColor:active ==='LogOut' ? '#f44336':'',padding:"5px"}}>LogOut</a></li>
           </ul>
           </nav>
 
      
      </div>
      </div>
      
    </>
    
  )
}

export default Nav