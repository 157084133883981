// import React, { useState, useEffect } from "react";
// import MaterialTable, {
//   MTableToolbar,
//   MTableHeader,
//   MTablePagination,
//   MTableBodyRow,
//   MTableBody,
// } from "material-table";
// import axios from "axios";


// const agentLogin = sessionStorage.getItem("loginEmail");

// function UpcomingCommission() {
//   const [data, setData] = useState([]);
//   const [selectedMaster, setSelectedMaster] = useState(null);
//   const [masterData, setMasterData] = useState([]);

//   useEffect(() => {
//     handleFetchData();
//   }, []);

//   const handleFetchData = async () => {
//     try {
//       const response = await axios.get("https://royalluck.club:5000/user/SuperCommissionMaster");
      
//       if (response.data.status === 200) {
//         setData(response.data.data);
//       } else {
//         setData([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const openModal = async (masterEmail) => {
//     try {
//       const response = await axios.post("https://royalluck.club:5000/user/MasterCommissionData", { email: masterEmail });
//       if (response.data.data) {
//         setMasterData(response.data.data);
//         setSelectedMaster(masterEmail);
//       } else {
//         console.error("No data found for selected master:", masterEmail);
//       }
//     } catch (error) {
//       console.error("Error retrieving data for selected master:", error);
//     }
//   };

//   const closeModal = () => {
//     setSelectedMaster(null);
//     setMasterData([]);
//   };

//   const modalBody = (
//     <div style={{ background: "white", color: "blue" }}>
//       <table style={{ width: "100%" }}>
//         <thead>
//           <tr>
//             <th>Email</th>
//             <th>Commission</th>
//           </tr>
//         </thead>
//         <tbody>
//           {masterData.map((item, index) => (
//             <tr key={index}>
//               <td>{item.master.email}</td>
//               <td>{item.commission[0].total_master_commission !== null ? item.commission[0].total_master_commission : 0}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );

//   const columns = [
//     { title: "SuperMasterName", field: "supermaster.email" },
//     { title: "Point Played", field: "commission[0].points_played" },
//     { title: "Total Commission of SuperMaster", field: "commission[0].total_supermaster_commission" },
//     { title: "Total Commission of Master", field: "commission[0].total_master_commission" },
//     { title: "",
//       render: (rowData) => (
//         <button className="btn btn-danger ml-2" style={{ zIndex: 2, position: 'relative' }} onClick={() => openModal(rowData.supermaster.email)}>
//           Commission of Master
//         </button>
//       ),
//     },
//   ];

//   return (
//     <>
//       <div className="card card-outline " id="daily">
//       <MaterialTable
//             title="Upcoming Commission Details"
//             data={data}
//             columns={columns}
//             options={{
//                 pageSize: 20,
//                 search: false,
//                 toolbar: true,
//                 header: true,
//                 thirdSortClick: false,
//                 tableLayout: "fixed",
//                 maxBodyHeight: "200px",
//                 toolbarStyle: { width: "1000%" },
//                 headerStyle: {
//                 textAlign: "center",
//                 whiteSpace: "nowrap",
//                 height: 20,
//                 maxHeight: 20,
//                 padding: 0,
//                 background: "#f44336",
//                 border: "1px solid black",
//                 borderTop: "6px solid white",
//                 borderBottom: "6px solid white",
//                 },
//                 rowStyle: {
//                 width: 20,
//                 maxWidth: 20,
//                 height: 20,
//                 maxHeight: 20,
//                 padding: 0,
//                 },
//                 toolbarStyle: {
//                 height: 10,
//                 maxHeight: 10,
//                 },
//                 rowStyle: { border: "1px solid black" },
//                 rowStyle: (data, index) =>
//                 index % 2 == 0 ? { background: "#e1a49f" } : null,
//                 cellStyle: { border: "1px solid black", alignItems: "center" }, // Adjust width for six columns
//                 paging: true,
//             }}
//             components={{
//                 Toolbar: (props) => (
//                 <div style={{ background: "orange", borderSpacing: "5px" }}>
//                     <MTableToolbar {...props} />
//                 </div>
//                 ),
//                 Row: (props) => (
//                 <div style={{}}>
//                     <MTableBodyRow {...props} />
//                 </div>
//                 ),
//                 Header: (props) => (
//                 <div style={{ }}> {/* Adjust width for header cell */}
//                     <MTableHeader {...props} />
//                 </div>
//                 ),
//                 Body: (props) => (
//                 <div style={{}}>
//                     <MTableBody {...props} />
//                 </div>
//                 ),
//             }}
//             />

//       </div>
//       {/* Modal */}
//       {selectedMaster && (
//   <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
//     <div className="modal-dialog" role="document" style={{ maxWidth: '800px' }}>
//       <div className="modal-content">
//         <div className="modal-header" style={{ backgroundColor: '#f7f7f7', borderBottom: '1px solid #ddd' }}>
//           <h5 className="modal-title">Master IDs and Commission</h5>
//           <button type="button" className="close" onClick={closeModal}>
//             <span aria-hidden="true">&times;</span>
//           </button>
//         </div>
//         <div className="modal-body" style={{ padding: '20px' }}>
//           {masterData.length === 0 ? (
//             <p>No data available</p>
//           ) : (
//             <div style={{ background: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
//               <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                 <thead>
//                   <tr style={{ backgroundColor: "#ddd" }}>
//                     <th style={{ padding: "10px", border: "1px solid #ddd" }}>Email</th>
//                     <th style={{ padding: "10px", border: "1px solid #ddd" }}>Commission</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {masterData.map((item, index) => (
//                     <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white" }}>
//                       <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.master.email}</td>
//                       <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.commission[0].total_master_commission !== null ? item.commission[0].total_master_commission : 0}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//         <div className="modal-footer" style={{ borderTop: '1px solid #ddd', padding: '20px' }}>
//           <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
//         </div>
//       </div>
//     </div>
//   </div>
// )}

//     </>
//   );
// }

// export default UpcomingCommission;
import React, { useState, useEffect } from "react";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableBody,
} from "material-table";
import axios from "axios";

const agentLogin = sessionStorage.getItem("loginEmail");

function UpcomingCommission() {
  const [data, setData] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(null);
  const [masterData, setMasterData] = useState([]);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    try {
      const response = await axios.get("https://royalluck.club:5000/user/SuperCommissionMaster");
      
      if (response.data.status === 200) {
        //item.supermaster.email = agentlogin rahega whi record dikhega 
        const filteredData = response.data.data.filter(item => item.supermaster.email === agentLogin);
        setData(filteredData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = async (masterEmail) => {
    try {
      const response = await axios.post("https://royalluck.club:5000/user/MasterCommissionData", { email: masterEmail });
      if (response.data.data) {
        setMasterData(response.data.data);
        setSelectedMaster(masterEmail);
      } else {
        console.error("No data found for selected master:", masterEmail);
      }
    } catch (error) {
      console.error("Error retrieving data for selected master:", error);
    }
  };

  const closeModal = () => {
    setSelectedMaster(null);
    setMasterData([]);
  };

  const modalBody = (
    <div style={{ background: "white", color: "blue" }}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Commission</th>
          </tr>
        </thead>
        <tbody>
          {masterData.map((item, index) => (
            <tr key={index}>
              <td>{item.master.email}</td>
              <td>{item.commission[0].total_master_commission !== null ? item.commission[0].total_master_commission : 0}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const columns = [
    { title: "SuperMasterName", field: "supermaster.email" },
    { title: "Point Played", field: "commission[0].points_played" },
    { title: "Total Commission of SuperMaster", field: "commission[0].total_supermaster_commission" },
    { title: "Total Commission of Master", field: "commission[0].total_master_commission" },
    { title: "",
      render: (rowData) => (
        <button className="btn btn-danger ml-2" style={{ zIndex: 2, position: 'relative' }} onClick={() => openModal(rowData.supermaster.email)}>
          Commission of Master
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="card card-outline " id="daily">
        <MaterialTable
          title="Upcoming Commission Details"
          data={data}
          columns={columns}
          options={{
            pageSize: 20,
            search: false,
            toolbar: true,
            header: true,
            thirdSortClick: false,
            tableLayout: "fixed",
            maxBodyHeight: "200px",
            toolbarStyle: { width: "1000%" },
            headerStyle: {
              textAlign: "center",
              whiteSpace: "nowrap",
              height: 20,
              maxHeight: 20,
              padding: 0,
              background: "#f44336",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },
            rowStyle: {
              width: 20,
              maxWidth: 20,
              height: 20,
              maxHeight: 20,
              padding: 0,
            },
            toolbarStyle: {
              height: 10,
              maxHeight: 10,
            },
            rowStyle: { border: "1px solid black" },
            rowStyle: (data, index) =>
              index % 2 === 0 ? { background: "#e1a49f" } : null,
            cellStyle: { border: "1px solid black", alignItems: "center" }, // Adjust width for six columns
            paging: true,
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ background: "orange", borderSpacing: "5px" }}>
                <MTableToolbar {...props} />
              </div>
            ),
            Row: (props) => (
              <div style={{}}>
                <MTableBodyRow {...props} />
              </div>
            ),
            Header: (props) => (
              <div style={{}}> {/* Adjust width for header cell */}
                <MTableHeader {...props} />
              </div>
            ),
            Body: (props) => (
              <div style={{}}>
                <MTableBody {...props} />
              </div>
            ),
          }}
        />
      </div>
      {/* Modal */}
      {selectedMaster && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog" role="document" style={{ maxWidth: '800px' }}>
            <div className="modal-content">
              <div className="modal-header" style={{ backgroundColor: '#f7f7f7', borderBottom: '1px solid #ddd' }}>
                <h5 className="modal-title">Master IDs and Commission</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: '20px' }}>
                {masterData.length === 0 ? (
                  <p>No data available</p>
                ) : (
                  <div style={{ background: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                      <thead>
                        <tr style={{ backgroundColor: "#ddd" }}>
                          <th style={{ padding: "10px", border: "1px solid #ddd" }}>Email</th>
                          <th style={{ padding: "10px", border: "1px solid #ddd" }}>Commission</th>
                        </tr>
                      </thead>
                      <tbody>
                        {masterData.map((item, index) => (
                          <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white" }}>
                            <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.master.email}</td>
                            <td style={{ padding: "10px", border: "1px solid #ddd" }}>{item.commission[0].total_master_commission !== null ? item.commission[0].total_master_commission : 0}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="modal-footer" style={{ borderTop: '1px solid #ddd', padding: '20px' }}>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UpcomingCommission;
