import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "../points/style/game.css";
import "../points/style/date.css";
import MaterialTable, {
  MTableToolbar,
  MTableHeader,
  MTablePagination,
  MTableBodyRow,
  MTableBody,
} from "material-table";
import axios from "axios";
import moment from "moment";

function DailyStatus() {
  let apiBaseURL = "https://royalluck.club:5000";
  const [singleDate, setSingleDate] = useState(null);
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState(""); // State to store user ID

  const columns = [
    { title: "Member ID ", field: "Agent_id" },
    { title: "Profit", field: "Profit"},
  ];


  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Add logic to retrieve logged user ID, replace 'userId' with actual ID retrieval
    const loggedInUserId = sessionStorage.getItem("loginEmail"); 

    // Call API with selected date and logged user ID
    await axios.get(`${apiBaseURL}/user/DailyStatus`, {
      params: {
        date: moment(singleDate).format("YYYY-MM-DD"),
        userId: loggedInUserId,
      },
    })
    .then(function (response) {
      if (response.data.status === 200) {
        setData(response.data.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  useEffect(() => {
    getReports();
  }, []);

  // Function to fetch data
  const getReports = async () => {
    await axios
      .get(`${apiBaseURL}/user/DailyStatus`)
      .then(function (response) {
        if (response.data.status === 200) {
          setData(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}> {/* Add onSubmit attribute to the form */}
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <div className="row-mid">
                  <label> Date:</label>
                  <DatePicker
                    selected={singleDate}
                    onChange={(date) => setSingleDate(date)}
                    singleDate={singleDate}
                    dateFormat="dd MMM yyyy"
                    calendarClassName="custom-datrpicker1"
                    selectsSingle
                  />
                </div>
                <div class="row-right show-details-button">
                  <input
                    type="submit"
                    name="btnShowDetails"
                    id="btnShowDetails"
                    value="Show Details"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </form>
      <div className="card card-outline " id="daily">
        <MaterialTable
          title="Daily Status"
          data={data}
          columns={columns}
          options={{
            pageSize: 20,
            search: false,
            toolbar: true,
            header: true,
            thirdSortClick: false,
            tableLayout: "fixed",
            maxBodyHeight: "200px",
            tollbarStyle: { width: "1000%" },
            headerStyle: {
              textAlign: "center",
              whiteSpace: "nowrap",
              height: 20,
              maxHeight: 20,
              padding: 0,
              background: "#f44336",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },
            rowStyle: {
              width: 20,
              maxWidth: 20,
              height: 20,
              maxHeight: 20,
              padding: 0,
            },
            toolbarStyle: {
              height: 10,
              maxHeight: 10,
            },
            rowStyle: { border: "1px solid black" },
            rowStyle: (data, index) =>
              index % 2 == 0 ? { background: "#e1a49f" } : null,
            cellStyle: { border: "1px solid black", alignItems: "center" },
            paging: true,
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ background: "orange", borderSpacing: "5px" }}>
                <MTableToolbar {...props} />
              </div>
            ),
            Row: (props) => (
              <div style={{}}>
                <MTableBodyRow {...props} />
              </div>
            ),
            Header: (props) => (
              <div>
                <MTableHeader {...props} />
              </div>
            ),
            Body: (props) => (
              <div style={{}}>
                <MTableBody {...props} />
              </div>
            ),
          }}
        />
      </div>
    </>
  );
}

export default DailyStatus;
