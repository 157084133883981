import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Card,
} from "@mui/material";

function MonthlyStatus() {
  const apiBaseURL = "https://royalluck.club:5000";
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const columns = [
    { title: "Player Name", field: "playerId" },
    { title: "Played Points", field: "playedPoint" },
    { title: "Win Points", field: "winPoint" },
    { title: "Loss Points", field: "losePoint" },
    { title: "Game ID", field: "game_id" },
    {
      title: "Date",
      field: "created_at",
      render: (rowData) => moment(rowData.created_at).format("DD MMM YYYY"),
    },
  ];

  const fetchPlayerData = async () => {
    try {
      const response = await axios.post(`${apiBaseURL}/user/getPlayerDatas`, {
        manager: sessionStorage.getItem("loginEmail"),
      });
      if (response.data.status === 200) {
        const fetchedData = response.data.data;
        setData(fetchedData);
        setFilteredData(fetchedData);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching player data:", error);
    }
  };

  useEffect(() => {
    fetchPlayerData();
  }, []);

  useEffect(() => {
    if (selectedMonth !== null) {
      const filtered = data.filter((item) => {
        const month = moment(item.created_at).month() + 1;
        return month === selectedMonth;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [selectedMonth, data]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Monthly Status
      </Typography>

      <FormControl sx={{ width: "250px", mb: 3 }}>
  <InputLabel>Select Month</InputLabel>
  <Select
    value={selectedMonth !== null ? selectedMonth : ""}
    onChange={(e) => setSelectedMonth(e.target.value ? parseInt(e.target.value) : null)}
    label="Select Month"
    MenuProps={{
      PaperProps: {
        sx: {
          maxHeight: "300px",
          overflowY: "auto",
        },
      },
    }}
  >
    <MenuItem value="">-- Select Month --</MenuItem>
    {[...Array(12)].map((_, index) => {
      const monthValue = index + 1;
      return (
        <MenuItem
          key={monthValue}
          value={monthValue}
          sx={{
            display: "flex", 
            flexDirection: "column", 
            alignItems: "flex-start", 
            padding: "8px 16px", 
          }}
        >
          {moment().month(index).format("MMMM")}
        </MenuItem>
      );
    })}
  </Select>
</FormControl>


      <Card variant="outlined" sx={{ p: 2 }}>
        <MaterialTable
          title="Monthly Status"
          data={filteredData}
          columns={columns}
          options={{
            pageSize: 20,
            search: false,
            toolbar: true,
            header: true,
            tableLayout: "fixed",
            maxBodyHeight: "200px",
            headerStyle: {
              textAlign: "center",
              background: "#f44336",
              border: "1px solid black",
              borderTop: "6px solid white",
              borderBottom: "6px solid white",
            },
            rowStyle: (data, index) => ({
              background: index % 2 === 0 ? "#e1a49f" : "white",
            }),
            cellStyle: { border: "1px solid black", textAlign: "center" },
          }}
        />
      </Card>
    </Box>
  );
}

export default MonthlyStatus;
